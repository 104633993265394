$(
	function() {
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.setBackUrls();
		app.attachSvgEvents();
		app.addCoverSpan();
		app.assignProductOver();
		app.saveProductListInfo();
		app.makeSocialButtons({
			marginLeft: -2
		});
	}
);

var app = {
	_vars: {
		v: 5,
		_guid: '3c5edf69-253c-487b-b362-2c8d9b97507d'
	},
	doBeforePriceSliderShown: function(trg) {
		var color = $('.noUi-connect').closest('.li-block').find('h3').css('background-color');
		$('.noUi-connect').css('background-color', color);
	}
};
